import React from "react"
import ShadowImage from "./shadowImage"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "../styles/components/_testimonial.scss"

const Testimonial = ({ image, quoteIcon, quote, attribution, shadow }) => {
  return (
    <div className="testimonial">
      <ShadowImage image={image} shadow={shadow} />
      <div className="testimonial-text">
        <img src={quoteIcon.file.url} alt={quoteIcon.description} />
        <div className="testimonial-quote">
          <div className="quote">{documentToReactComponents(quote)}</div>
          <div className="text-break"></div>
          <div className="quote-attribution">{attribution}</div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
