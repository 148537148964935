import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "../styles/components/_sectionTitle.scss"

export default ({ title }) => (
  <StaticQuery
    query={graphql`
      query SectionTitleQuery {
        allContentfulGeneral {
          nodes {
            redUnderline {
              file {
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="section-title">
        <h2>{title}</h2>
        <div
          className="underline"
          style={{
            backgroundImage: `url(${data.allContentfulGeneral.nodes[0].redUnderline.file.url})`,
          }}
        ></div>
      </div>
    )}
  />
)
