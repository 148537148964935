import React from "react"
import BackgroundImage from "gatsby-background-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "../styles/components/_highlightSection.scss"

const HighlightSection = ({ background, highlights }) => {
  return (
    <div className="highlight-section">
      <ul className="highlights">
        {highlights.map((highlight, n) => {
          return (
            <li key={n}>
              <img
                src={highlight.icon.file.url}
                alt={highlight.icon.description}
              />
              <div className="highlight-text">
                <h3>{highlight.tItle}</h3>
                <div className="highlight-summary">
                  {documentToReactComponents(highlight.summary.json)}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      <BackgroundImage
        fluid={background.fluid}
        className="highlight-bg"
      ></BackgroundImage>
    </div>
  )
}

export default HighlightSection
