import React from "react"
import "../styles/components/_positions.scss"

const Positions = ({ positions }) => {
  let departments = []

  positions.map(position => {
    const index = departments
      .map(e => e.department)
      .indexOf(position.department)

    if (index > -1) {
      departments[index].positions.push(position)
    } else {
      const departmentTitle =
        position.department !== "" ? position.department : "Other"
      const addition = {
        department: departmentTitle,
        positions: [position],
      }
      departments.push(addition)
    }

    return null
  })

  return (
    <ul className="positions">
      {departments.map((department, n) => {
        return (
          <li key={n}>
            <h3>{department.department}</h3>
            <ul className="department-positions">
              {department.positions.map((position, n) => {
                let location
                if (position.city && position.city !== "") {
                  location = `${position.city}, ${position.state}`
                } else if (position.country_id) {
                  location = position.country_id
                } else {
                  location = "Remote"
                }
                return (
                  <li key={n}>
                    <a
                      href={`https://talentwwinc.applytojob.com/apply/${position.board_code}/`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <h4>{position.title}</h4>
                      <div className="location">{location}</div>
                      <div className="job-type">{position.type}</div>
                    </a>
                  </li>
                )
              })}
            </ul>
          </li>
        )
      })}
    </ul>
  )
}

export default Positions
