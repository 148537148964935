import React from "react"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import "../styles/components/_shadowImage.scss"

const ShadowImage = ({ image, shadow }) => {
  const imgStyle = {
    position: "absolute",
  }
  return (
    <div className="shadow-image">
      <Img
        fluid={image.fluid}
        objectFit="contain"
        alt={image.description}
        style={imgStyle}
      />
      <BackgroundImage
        fluid={shadow.fluid}
        className="shadow"
        style={imgStyle}
      ></BackgroundImage>
    </div>
  )
}

export default ShadowImage
